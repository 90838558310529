import { QuestionCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { Payments } from 'gadjet-v2-types/dist/model'
import { PaymentStatus, PaymentType, Pagination } from 'gadjet-v2-types/dist/type'
import { paymentStatus, paymentType } from 'gadjet-v2-types/dist/type/label'

import { krw } from '@utils/format'

import { BranchPaymentAction } from '@pages/Branch/PaymentPage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'
import TypeTag from '@components/molecules/TypeTag'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  payments: Payments[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchPaymentAction, paymentId: number) => void
}
export default function BranchPaymentListTable({
  loading,
  total,
  pagination,
  payments,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Payments>[] = [
    {
      key: 'billId',
      dataIndex: 'billId',
      title: '입주사',
      render: (_, { bill }) => {
        return bill?.contract?.tenant?.name || bill?.tenant?.name || '-'
      },
    },
    { key: 'type', dataIndex: 'type', title: '타입', render: (type: PaymentType) => paymentType[type] },
    { key: 'item', dataIndex: 'item', title: '상품', render: (_, { item }) => item || '-' },
    {
      key: 'price',
      dataIndex: 'price',
      title: '결제금액',
      align: 'right',
      render: (price) => krw(price),
    },
    {
      key: 'commissionPrice',
      dataIndex: 'commissionPrice',
      title: '수수료',
      align: 'right',
      render: (commissionPrice) => krw(commissionPrice),
    },
    {
      key: 'settlementPrice',
      dataIndex: 'settlementPrice',
      title: '정산금액',
      align: 'right',
      render: (settlementPrice) => krw(settlementPrice),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: '상태',
      render: (status: PaymentStatus) => (
        <TypeTag<PaymentStatus>
          label={paymentStatus[status]}
          value={status}
          switchCase={{ values: [['ready'], ['approve'], ['refund']], types: ['ongoing', 'success', 'fail'] }}
        />
      ),
    },
    {
      key: 'paymentId',
      dataIndex: 'paymentId',
      title: '날짜',
      render: (_, payment) => {
        const date = payment.refundDatetime || payment.approveDatetime || payment.readyDatetime
        return <Tooltip title={payment.paymentId}>{dayjs(date).format('YYYY-MM-DD HH:mm:ss')}</Tooltip>
      },
    },
    {
      key: 'confirmFlag',
      dataIndex: 'confirmFlag',
      title: '매출확정',
      align: 'center',
      render: (_, { confirmFlag }) =>
        confirmFlag ? <TypeTag type="success" label="확정" /> : <TypeTag type="fail" label="미확정" />,
    },
    {
      title: (
        <Tooltip
          title={
            <div>
              앱 결제 - 이용자가 가젯 앱을 통해 결제한 내역 입니다.
              <br />
              직접입력 - 관리자가 직접 가젯에 입력한 결제내역입니다.
            </div>
          }
        >
          입력방식
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      align: 'center',
      render: (_, { manualFlag }) =>
        manualFlag ? <TypeTag type="default" label="직접입력" /> : <TypeTag type="success" label="앱 결제" />,
    },
    {
      key: 'paymentId',
      dataIndex: 'paymentId',
      title: (
        <Tooltip title="직접입력한 결제내역만 수정, 삭제할 수 있습니다." placement="rightTop">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      align: 'center',
      width: '80px',
      render: (paymentId, { confirmFlag, status, manualFlag, type }) => (
        <Dropdown
          overlay={
            manualFlag ? (
              <Menu>
                <Menu.Item key="update" onClick={() => onAction('update', paymentId)}>
                  수정
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => onAction('delete', paymentId)}>
                  삭제
                </Menu.Item>
              </Menu>
            ) : (
              <Menu>
                <Menu.Item
                  key="confirm"
                  onClick={() => onAction('confirm', paymentId)}
                  disabled={status !== 'approve' || confirmFlag}
                >
                  매출확정
                </Menu.Item>
                <Menu.Item
                  key="refund"
                  onClick={() => onAction('refund', paymentId)}
                  disabled={status !== 'approve' || confirmFlag || type === 'direct'}
                >
                  환불
                </Menu.Item>
              </Menu>
            )
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <TableWrapper>
      <Table<Payments>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ paymentId }) => `${paymentId}`}
        dataSource={payments}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomCenter'],
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
      />
    </TableWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
