import { Styles } from '@types'
import { Input, Select, Tag } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { SpaceTypes } from 'gadjet-v2-types/dist/model'
import { BillType, ReceiptStatus } from 'gadjet-v2-types/dist/type'
import { billType, receiptStatus } from 'gadjet-v2-types/dist/type/label'
import { BillSearch } from 'gadjet-v2-types/dist/type/search'
import { RangeValue } from 'rc-picker/lib/interface'
import { useEffect, useMemo } from 'react'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  search: BillSearch
  onChangeSearch: (search: Partial<BillSearch>) => void
  spaceTypes: SpaceTypes[]
  onSearch: () => void
  branchId: number
}

export default function BranchBillSearchBox({
  search,
  onChangeSearch,
  spaceTypes,
  onSearch,
  branchId,
}: Props): JSX.Element {
  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch({ query: e.target.value })
  const onChangeSpaceType = (_spaceTypes: number[]) => onChangeSearch({ spaceTypes: _spaceTypes })
  const onChangeBillType = (_type: BillType[]) => onChangeSearch({ type: _type })
  const onChangeReceiptStatusType = (_type: ReceiptStatus[]) => onChangeSearch({ receiptStatus: _type })

  const onChangeDateRange = (_value: RangeValue<Dayjs>) => {
    const start = _value?.[0]?.format('YYYY-MM-DD') || search.startDate[0]
    const end = _value?.[1]?.format('YYYY-MM-DD') || search.startDate[1]
    onChangeSearch({ startDate: [start, end] })
  }

  const rangeValue = useMemo((): [Dayjs, Dayjs] => {
    const [start, end] = search.startDate
    return [dayjs(start), dayjs(end)]
  }, [search])

  const billTypes = useMemo(
    () => [
      { key: 'sales', label: billType.sales },
      { key: 'deposit', label: billType.deposit },
    ],
    []
  )

  const receiptStatusTypes = useMemo(
    () => [
      { key: 'before-receipt', label: receiptStatus['before-receipt'] },
      { key: 'none', label: receiptStatus.none },
      { key: 'seperately-receipt', label: receiptStatus['seperately-receipt'] },
      { key: 'card', label: receiptStatus.card },
      { key: 'tax-invoice-done', label: receiptStatus['tax-invoice-done'] },
      { key: 'cash-receipt-done', label: receiptStatus['cash-receipt-done'] },
      { key: 'cash-receipt-cancel', label: receiptStatus['cash-receipt-cancel'] },
      { key: 'etc', label: receiptStatus.etc },
    ],
    []
  )

  useEffect(() => {
    onChangeSpaceType([])
  }, [branchId])
  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="날짜" span={4} helpText="제공기간의 시작일을 기준으로 청구서를 검색합니다">
        <LocalDatePicker.RangePicker
          ranges={{
            '이번 달': [dayjs().startOf('month'), dayjs().endOf('month')],
            '지난 달': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
          }}
          size="large"
          style={styles.datePicker}
          value={rangeValue}
          onChange={onChangeDateRange}
        />
      </SearchItem>

      <SearchItem label="타입" span={4}>
        <Select
          mode="multiple"
          onChange={onChangeBillType}
          size="large"
          value={search.type}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {billTypes.map((bt) => (
            <Select.Option key={bt.key} value={bt.key}>
              {bt.label}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>

      <SearchItem label="증빙현황" span={4}>
        <Select
          mode="multiple"
          onChange={onChangeReceiptStatusType}
          size="large"
          value={search.receiptStatus}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {receiptStatusTypes.map((bt) => (
            <Select.Option key={bt.key} value={bt.key}>
              {bt.label}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>

      <SearchItem label="공간유형" span={6}>
        <Select
          mode="multiple"
          onChange={onChangeSpaceType}
          size="large"
          value={search.spaceTypes}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {spaceTypes.map((st) => (
            <Select.Option key={st.spaceTypeId} value={st.spaceTypeId}>
              {st.name}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>
      <SearchItem label="검색어" span={6}>
        <Input size="large" placeholder="검색어를 입력하세요." value={search.query} onChange={onChangeQuery} />
      </SearchItem>
    </SearchBox>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
  select: { width: '100%' },
}
